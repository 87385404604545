<template lang="pug">
    .layout-general 
        .flex.items-center.justify-between.mb-5
            .flex.items-center.gap-4 
                .block.w-64
                    .field.field--icon-right.w-full
                        .field-style.field-style--loading(v-if="status_list_gatra.status === 'loading'")
                            b-loading(kind="circular")

                        .relative(v-else)
                            select.field-style(v-model="gatra" @change="getData(true)")
                                option(value="none") - Pilih Gatra -
                                template(v-if="list_gatra.length > 0")
                                    option(v-for="item in list_gatra" :value="item.id") {{ item.label }}
                            span.icon-field
                                b-icon(name="chevron-down")
                //- .block.w-40
                    .field.field--icon-right.w-full
                        select.field-style()
                            option Semua Gatra
                            option Gatra A
                            option Gatra B
                            option Gatra C
                            option Gatra D
                            option Gatra E
                        span.icon-field
                            b-icon(name="chevron-down")
                //- .block.w-80
                    .field.field--icon-left.w-full
                        input.field-style(placeholder="Cari ancaman..." v-model="keyword" @keyup="searchKeyword")
                        span.icon-field
                            b-icon(name="search")

            .block
                router-link(:to="{ name: 'GeneralAncamanCreate', params: {} }")
                    b-button(text="Ancaman Baru" kind="primary" text_icon_left="plus")

        .block.w-full
            .card-bg-theme.p-6.rounded.shadow.w-full
                table.main-table
                    thead
                        tr
                            th(v-for="(th,index) in tableThead" :key="index" :class="(th.number && th.number===true ? 'number' : '')+(th.action && th.action===true ? ' thead-action' : '')") {{ th.name }}
                    tbody
                        tr(v-if="status_list.status === 'loading'")
                            td.text-center(:colspan="tableThead.length")
                                .relative.p-10
                                    b-loading(text="Memuat data")
                        
                        template(v-else-if="list && list.length > 0")
                            tr(v-for="(data,index) in list" :key="data.id")
                                //- td {{ gatra && gatra.length > 0 ? list_gatra.filter(function(item) { return item.id === gatra })[0].label : '-' }}
                                td {{ gatra_label}}
                                td {{ data.label ? data.label : '-' }}
                                td
                                    .flex.justify-end.w-full
                                        router-link.mr-2(:to="'ancaman/edit/'+ encodeDetailData(data)" v-tooltip="'Ubah'")
                                            b-button( icon_only="edit" text="Edit")
                                        b-button(kind="danger" icon_only="trash" text="Delete" @click.native="deleteData(data.id, data.gatra_id, data.label)"  v-tooltip="'Hapus'")
                        
                        tr(v-else)
                            td.text-center.p-10(:colspan="tableThead.length") Ancaman tidak ditemukan.

</template>

<script>
import { mapState } from 'vuex';

export default {
    name: 'GeneralAncamanIndex',
    data() {
        return {
            tableThead: [
                {
                    name: 'Gatra',
                    // number: true,
                },{
                    name: 'Label',
                },{
                    name: '',
                    action: true,
                },
            ],
            
            gatra: '',
        }
    },
    computed: {
        ...mapState('graph', [
            'status_list_gatra',
            'list_gatra',
        ]),
        ...mapState('ancaman', [
            'status_list',
            'list',
        ]),
        gatra_label() {
            let gatra = this.gatra;
            return gatra && gatra.length > 0 ? this.list_gatra.filter(function(item) { return item.id === gatra })[0].label : '';
        },
    },
    watch: {
        list_gatra(newVal) {
            //- console.log(newVal)
            if (newVal && newVal.length > 0) {
                this.gatra = newVal[0].id;
                this.getData(true);
            }
        },
    },
    methods: {
        getData(get_ancaman=false, get_gatra=false) {
            //- if (reset) {
            //-     this.list_data = [];
            //-     this.page = 1;
            //- }

            if (get_gatra === true) {
                this.$store.dispatch('graph/getListGatra');
                this.$store.commit('ancaman/setStatusList', {
                    status: 'loading',
                    cancel: null,
                    code: null,
                });
            }

            this.$store.commit('ancaman/setList', []);

            if (get_ancaman === true)
                this.$store.dispatch('ancaman/getList', this.gatra);
        },
        deleteData(id, gatra_id, label) {
            this.$swal.fire({
                icon: 'warning',
                title: 'Apakah anda benar-benar ingin menghapus ini?',
                text: 'Ancaman : ' + this.gatra_label +' - '+ label,
                confirmButtonText: 'Ya!',
                confirmButtonColor: '#DD6B55',
                showCancelButton: true,
                allowOutsideClick: false,
            })
            .then((result) => {

                if (result.isConfirmed) {
                    Event.$emit('showLoading', true);

                    this.$store.dispatch('ancaman/delete', [id, gatra_id])
                    .then((resp) => {
                        if (resp === 'success') {
                            this.page = 0;

                            this.getData(true);

                            this.$swal.fire({
                                icon: 'success',
                                title: 'Ancaman sudah dihapus!',
                                timer: 3000,
                            });
                        } else {
                            if (resp.data && resp.data.message) {
                                Event.$emit('error', resp.data.message);
                            } else {
                                Event.$emit('error');
                            }
                        }
                    });
                }
            });
        },
    },
    beforeRouteEnter (to, from, next) {
        next(vm => {
            vm.$store.commit('ancaman/setList', []);
            if (to.name === 'GeneralAncamanIndex')
                vm.getData(false, true);
        });
    },
}
</script>